import React, { ReactElement } from 'react'
import ScreenRectangle from '../components/screen-rentangle/screen-rectangle'
import { Col, Container, Row } from 'react-grid-system'
import Surface from '../components/surface/surface'
import QRCode from 'react-qr-code'
import { Helmet } from 'react-helmet'
import useWindowDimensions from '../utils/use-window-dimension'
import qs from 'qs'
import ReactHtmlParser from 'react-html-parser'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { useIntl } from 'react-intl'

const OnboardingScreen = (): ReactElement => {
  const { height } = useWindowDimensions()

  const phoneHeight = height * 0.9
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })

  // TODO: Org settings
  const orgSettings = {
    color: '#1d2027',
    logo: 'https://images.jigx.com/jigxweek21-email-logo.png',
    namespace: 'jigxweek21',
    appName: 'JigxWeek21',
    appDomain: window.location.hostname, // 'app.jigx.com', 'mymotul.jigx.com'
    appIdIOS: '',
    appIdAndroid: '',
  }

  const qrValue = `${orgSettings.appDomain}://onboarding/confirm-invite/?invite=${query.invite}`
  const buttonValue = `${orgSettings.appDomain}://onboarding/confirm-invite/?invite=${query.invite}`
  const { formatMessage } = useIntl()

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/safari-pinned-tab.svg" color="#ee212c`}
        />
        <link
          rel="shortcut icon"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon.ico`}
        />
        <meta
          name="apple-itunes-app"
          content={`app-id=${orgSettings.appIdIOS}, app-argument=${qrValue}`}
        />
        <meta name="msapplication-TileColor" content={orgSettings.color} />
        <meta
          name="msapplication-config"
          content={`https://images.jigx.com/favicons/${orgSettings.namespace}/browserconfig.xml`}
        />
        <meta name="theme-color" content={orgSettings.color} />
      </Helmet>
      <ScreenRectangle color={orgSettings.color} />
      <div className="screen-wrapper">
        <div className="content-container">
          <Container style={{ maxWidth: '530px' }}>
            <Row>
              <Col xs={12} className="flex flex--align-center">
                <Surface isMobile={isMobile}>
                  <div className="logo__container">
                    <img
                      className="logo__img"
                      alt={`${orgSettings.appName} logo`}
                      height={phoneHeight}
                      style={{ maxWidth: '275' }}
                      src={orgSettings.logo}
                    />
                  </div>
                  <h1>
                    {formatMessage(
                      {
                        id: 'get-started-title',
                        defaultMessage: 'Get started on {appName}',
                      },
                      {
                        appName: orgSettings.appName,
                      },
                    )}
                  </h1>
                  <BrowserView>
                    <p className="intro padding-bottom--large">
                      {formatMessage(
                        {
                          id: 'browser-view-introduction',
                          defaultMessage:
                            'This is an exclusive invite to your {appName} app. Just go to Testflight and download the {appName} app and accept the invitation below. See you all in Brno!',
                        },
                        { appName: orgSettings.appName },
                      )}
                    </p>
                    <Row>
                      <Col xs={12}>
                        <div className="padding-bottom--medium">
                          <h2 >
                            {formatMessage({
                              id: 'how-to-start-desktop',
                              defaultMessage: 'How to start on the desktop?',
                            })}
                          </h2>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <ol>
                          <li className="padding-bottom--medium">
                            {formatMessage(
                              {
                                id: 'how-to-start-desktop-step-1',
                                defaultMessage:
                                  'Download the {appName} app from the Apple App Store or Google Play Store to your phone.',
                              },
                              {
                                appName: <strong>{orgSettings.appName}</strong>,
                              },
                            )}
                          </li>
                          <li className="padding-bottom--medium">
                            {ReactHtmlParser(
                              formatMessage({
                                id: 'how-to-start-desktop-step-2',
                                defaultMessage:
                                  'Scan this QR code from the desktop with your phone camera to <strong>accept your invitation.</strong>',
                              }),
                            )}
                          </li>
                        </ol>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <div className="flex flex--justify-center">
                          <div
                            style={{
                              padding: '5px',
                              paddingBottom: 0,
                              backgroundColor: 'white',
                            }}>
                            <QRCode value={qrValue} size={100} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </BrowserView>
                  <MobileView>
                    <Row>
                      <Col xs={12} className="flex flex--align-center flex--justify-center">
                        <div className="flex  flex--align-center" style={{ flexGrow: 1 }}>
                          <h2>
                            {formatMessage({
                              id: 'how-to-start-mobile',
                              defaultMessage: 'How to start?',
                            })}
                          </h2>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <ol className="mobile-list-item">
                          <li className="padding-bottom--medium">
                            <div className="padding-bottom--medium">
                              {formatMessage({
                                id: 'how-to-start-mobile-step-1',
                                defaultMessage: 'Download the app',
                              })}
                            </div>
                          </li>
                          <li className="padding-bottom--medium">
                            {formatMessage({
                              id: 'accept-your-invitation',
                              defaultMessage: 'Accept your invitation',
                            })}
                          </li>
                        </ol>
                      </Col>
                    </Row>
                    <div>
                      <a
                        href={buttonValue}
                        className="button button-link"
                        style={{ backgroundColor: orgSettings.color }}>
                        {formatMessage({
                          id: 'button-accept-your-invitation',
                          defaultMessage: 'Accept invitation',
                        })}
                      </a>
                    </div>
                  </MobileView>
                </Surface>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="flex flex--justify-center padding-top--medium">
                <p className="footer">Powered by Jigx, Inc.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default React.memo(OnboardingScreen)
