import React, { ReactElement } from 'react'

import { BrowserRouter, Route } from 'react-router-dom'
import OnboardingScreen from './screens/onboarding'

import './app.scss'
import IntlProvider from './utils/intl-provider'

const App = (): ReactElement => {
  return (
    <IntlProvider>
      <div className="App">
        <BrowserRouter>
          <Route path="/onboarding">
            <OnboardingScreen />
          </Route>
        </BrowserRouter>
      </div>
    </IntlProvider>
  )
}

export default App
